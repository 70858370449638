import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="index-page">
    <hr/>
    <p>Structre aims to organize your home improvement path.</p>
    <p>More information coming soon. Please check back!</p>
    </div>
  </Layout>
)

export default IndexPage
